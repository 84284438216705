import {delMany} from "idb-keyval";
import {UseKeysIDB} from "~/composables/keysIDB.js";


export const useRemoveData = async (newData, oldData, assignments = false) => {
    try {
        let newArr = []
        let oldArr = []
        let hitList = []
        newData.forEach((item) => {
            newArr.push(Number(item.id))
        })
        oldData.forEach((item) => {
            oldArr.push(Number(item.split('-')[1]))
        })
        oldArr.forEach((item) => {
            if (!newArr.find(inst => inst === item)) {
                hitList.push(`${!assignments?'ticket':'assignment'}-${item}`)
            }
        })
        console.log(hitList);
        if (assignments) {
            await delMany(hitList, UseKeysIDB().assignmentsData)
        } else {
            await delMany(hitList, UseKeysIDB().ticketsData)
        }
    } catch (err) {}
}