import {UseGetData} from "~/composables/getData.js";
import { keys } from 'idb-keyval';
import { UseKeysIDB } from "~/composables/keysIDB.js";
import { UseCreateTicketsData } from "~/composables/IDB/tickets/createTicketsData.js";
import {useUserStore} from "~/stores/user.js";
import {useFillTicketLists} from "~/composables/general/fillTicketLists.js";
import {useRemoveData} from "~/composables/general/removeData.js";


export const useGetTicketList = async () => {
    try {
        console.log('getTicketListlaunched')
        const userStore = useUserStore()
        const config = useRuntimeConfig()
        try {
            await fetch(`${config.public.apiBase}/tickets`, {
                method: 'GET',
                headers: {
                    Authorization: "Bearer " + userStore.token,
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                }
            }).then(async (response) => {
                let res = await response.json();
                let ticketsKeys = await keys(UseKeysIDB().ticketsData)
                await useRemoveData(res.Data, ticketsKeys)
                await UseCreateTicketsData(res.Data)
            }).catch(err => {})
            await useFillTicketLists()
        } catch (err) {
            console.log(err, 'err during fetching ticket list')
            await useFillTicketLists()
        }
    } catch (e) {
        console.log("error in getTicketsList", e)
        await useFillTicketLists()
    }
}