import {useGeneralApp} from "~/stores/generalApp.js";
import {useUserStore} from "~/stores/user.js";
import {useCreateAssignmentsData} from "~/composables/IDB/tickets/createAssignmentsData.js";
import { keys } from 'idb-keyval';
import {UseGetData} from "~/composables/getData.js";
import {delMany} from "idb-keyval";
import {useRemoveData} from "~/composables/general/removeData.js";

export const useGetAssignmentsList = async () => {
    const generalApp = useGeneralApp()
    const userStore = useUserStore()
    const config = useRuntimeConfig()
    let tempList = []
    const getAssignmentsData = async () => {
        let assignmentsKeys = await keys(UseKeysIDB().assignmentsData)
        for (let key of assignmentsKeys) {
            let data = await UseGetData('assignments', key)
            let media_attachments = []
            let newTicket = {
                id: data.id,
                number: data.number,
                status: data.status,
                date: data.date,
                user_id: data.user_id,
                emergency_type: data.emergency_type,
                description: data.description,
                danger_category: data.danger_category,
                danger_zone: data.danger_zone,
                danger_sub_zone: data.danger_sub_zone,
                subject: data.subject,
                media_attachments: media_attachments,
                comment: '',
                notifications: true,
                hasAttachments: data.hasAttachments
            }
            tempList.unshift(newTicket)
        }
    }
    const fillAssignmentsList = async () => {
        await getAssignmentsData()
        userStore.testUser.assigned_tickets = tempList.sort(function(a, b) {
            return new Date(b.date) - new Date(a.date)
        })
        generalApp.currentListOfTickets = tempList.sort(function(a, b) {
            return new Date(b.date) - new Date(a.date)
        })
    }
    try {
        console.log('get assignments list launched')
        let response = await fetch(`${config.public.apiBase}/tickets/assigned`, {
            method: "GET",
            headers: {
              ContentType: "application/json",
              Accept: "application/json",
              Authorization: "Bearer " + userStore.token,
            },
        })
        if (response.status === 200) {
            let res = await response.json()
            let assignmentsKeys = await keys(UseKeysIDB().assignmentsData)
            await useRemoveData(res.Data, assignmentsKeys, true)
            await useCreateAssignmentsData(res.Data)
        }
        await fillAssignmentsList()
    } catch (err) {
        await fillAssignmentsList()
        console.log(err, 'error during get assignments');
    }
}